/* :root {
    --bg-navbar: #000000;
    --bg-primary: #D50000;
    --bg-secondary: #404040;
    --bt-confirm: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --text-bt-confirm: #000000;
    --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7.81%, #F00000 25.52%, #C20000 100%);
    --text-bt-login: #FFFFFF;
    --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9.38%, #FFA800 27.08%, #FF6B00 100%);
    --bg-label-money: #262626;
    --bg-camp: #000000;
    --text-secondary: #989898;
    --bg-card: linear-gradient(180deg, #434343 0%, #2F2F2F 12.5%, #1C1C1C 89.06%);
    --bg-select: linear-gradient(180deg, #D40000 0%, #FF6060 9.11%, #D40000 45.05%, #D40000 73.18%, #D40000 100%);
    --bg-floor: #0D0D0D;
    --bg-menu-floor: #000000;
    --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29.17%, #DF0000 72.4%, #000000 100%);
    --bt-sucess: #50B700;
    --bt-danger: #EA0000;
    --bg-warning: #FFE000;
    --bg-blue: #0034BB;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-text-main: #FFFFFF;
    --bg-play-game: #202020;
    --bg-popup: #272829;
    --bt-secondary: #A0A0A0;
} */

/* .profileContainer {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 70px 20px 20px 20px;
} */

.profileCard {
    background: var(--bg-primary);
    border: solid 1px var(--bg-secondary);
    border-radius: 10px;
    padding: 20px;
    color: var(--bg-white);
}

.profileHead {
    text-align: center;
    margin-top: -70px;
}

.profileIcon {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 4px solid var(--bg-white);
}

.profileTitle {
    font-size: 18px;
    font-weight: 600;
    color: var(--bg-text-main);
    padding: 18px 0px 10px 0px;
}

.infoList {
    background: var(--bg-white);
    border-radius: 10px;
    padding: 10px;
    color: var(--bg-black);
    font-weight: 600;
    font-size: 16px;
    vertical-align: middle;
    margin: 10px 0px;
}

.profileChangePass {
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 600;
    color: var(--bg-text-main);
    padding: 18px 0px 10px 0px;
}

.bankImg {
    border-radius: 50%;
    width: 75px;
    height: 75px;
}

.bankName {
    font-weight: 600;
    font-size: 18px;
}

.bankAccount {
    font-weight: 500;
    font-size: 14px;
}

.bankNumber {
    font-weight: 600;
    font-size: 16px;
}

.btnAddBank {
    border: 1px solid var(--bg-white);
    border-radius: 25px;
    background: transparent;
    padding: 12px 0px;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 600;
}

.btnAddBank:hover {
    top: 2px;
    background: var(--bg-white);
    color: var(--bt-danger);
    
}

/* .addBankContent {
    color: var(--bg-white);
    padding: 15px 0px;
} */

.addBankTitle {
    font-size: 20px;
    color: var(--bg-white);
    font-weight: 600;
}

.validateError {
    color: var(--bt-danger);
    padding-top: 10px;
}

.validateWarning {
    color: var(--bg-warning);
    padding-top: 10px;
}

@media only screen and (max-width: 600px) {
    .profileContainer {
        padding: 0px 5px;
    }
    .profileHead {
        text-align: center;
        margin-top: -50px;
    }
    .profileIcon {
        width: 61px;
        height: 61px;
        border-radius: 50%;
        border: 2px solid var(--bg-white);
    }
    .bankImg {
        vertical-align: top;
        width: 40px;
        height: 40px;
    }
    .addBankContainer {
        padding: 0px 5px;
        font-size: 14px;
    }
    .bankName,
    .bankNumber {
        font-size: 14px;
    }
    .profileTitle,
    .profileChangePass {
        font-size: 16px;
    }

    .profileTitle {
        padding: 10px 0px 10px 0px;;
    }

    .btnAddBank {
        font-size: 16px;
        padding: 8px 0px;
    }
}