/* :root {
    --bg-navbar: #000000;
    --bg-primary: #D50000;
    --bg-secondary: #404040;
    --bt-confirm: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9#modal .38%, #FFA800 27#modal .08%, #FF6B00 100%);
    --text-bt-confirm: #000000;
    --bt-login: linear-gradient(180deg, #F00000 0%, #FF6262 7#modal .81%, #F00000 25#modal .52%, #C20000 100%);
    --text-bt-login: #FFFFFF;
    --bg-brand: linear-gradient(180deg, #FFC75B 0%, #FFE4AF 9#modal .38%, #FFA800 27#modal .08%, #FF6B00 100%);
    --bg-label-money: #262626;
    --bg-camp: #000000;
    --text-secondary: #989898;
    --bg-card: linear-gradient(180deg, #434343 0%, #2F2F2F 12#modal .5%, #1C1C1C 89#modal .06%);
    --bg-select: linear-gradient(180deg, #D40000 0%, #FF6060 9#modal .11%, #D40000 45#modal .05%, #D40000 73#modal .18%, #D40000 100%);
    --bg-floor: #FFFFFF;
    --bg-menu-floor: #000000;
    --bg-label-text: linear-gradient(90deg, #000000 0%, #DF0000 29#modal .17%, #DF0000 72#modal .4%, #000000 100%);
    --bt-sucess: #50B700;
    --bt-danger: #EA0000;
    --bg-warning: #FFE000;
    --bg-blue: #0034BB;
    --bg-black: #000000;
    --bg-white: #FFFFFF;
    --bg-text-main: #FFFFFF;
    --bg-play-game: #202020;
    --bg-popup: #272829;
    --bg-floor-popup: rgba(106, 0, 0, 0#modal .6);
} */

#modal ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    opacity: 0;
}

#modal .fixedBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--bg-floor-popup);
    /* z-index: 1400; */
    opacity: 0.6;
}

#modal .contentPosition {
    position: fixed;
    top: 50%;
    left: 50%;
    display: block;
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: opacity 0.15s linear;
    transform: translate(-50%, -50%);
}

#modal .box,
#modal .boxFull {
    border: 2px solid var(--bg-line);
    border-radius: 16px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    outline: 0;
}

#modal .boxBody,
#modal .boxBodyFull {
    position: relative;
    width: auto;
    /* margin: 1#modal .5rem; */
    pointer-events: none;
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

#modal .boxContent,
#modal .boxContentFull {
    padding: 40px 50px 30px 50px;
    width: 430px;
    height: 100%;
    text-align: left;
    background: var(--bg-navbar);
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

#modal .boxContentError {
    padding: 30px 30px 30px 30px;
    width: 420px;
    height: 100%;
    text-align: left;
    background: var(--bg-white);
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

/* #modal .boxContentFull {
    width: 600px;
} */

#modal .boxContentAlert {
    width: 400px;
}

#modal .confirmParagrap {
    font-size: 20px;
    color: var(--bg-black);
    margin: 20px 0px 30px 0px;
}

#modal .confirmTitle {
    font-size: 20px;
    color: var(--bg-black);
    margin: 20px 0px 30px 0px;
}

#modal .confirmText {
    font-size: 20px;
    color: white;
}

#modal .closeButtonFull {
    position: absolute;
    /* top: 50%; */
    right: 0px;
    /* z-index: 1600; */
    padding: 10px;
}

#modal .backButtonFull {
    padding: 10px;
    top: -38px;
    left: -50px;
}

#modal .closeButton {
    position: absolute;
    top: -16px;
    right: -16px;
    /* z-index: 1600; */
}

#modal .iconImgFull {
    position: relative;
    color: white;
    width: 46px !important;
    height: 100% !important;
    cursor: pointer;
}

#modal .closeImg,
#modal .closeImgFull {
    position: relative;
    width: 60px;
    height: 100%;
    cursor: pointer;
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

#modal .closeImg:hover,
#modal .iconImgFull:hover {
    top: 2px;
}

#modal .conditionTitle {
    padding: 20px 20px 10px 20px;
    color: var(--bg-white);
    font-size: 18px;
    border-bottom: 1px solid var(--bg-line);
    background: var(--bg-brand);
    border-radius: 10px 10px 0px 0px;
}

#modal .conditionTitlePaddingOther {
    padding: 20px 20px 20px 20px;
}

#modal .conditionMaginTop {
    margin-top: 10px;
}

#modal .conditionHead {
    padding: 20px 20px 10px 20px;
    color: var(--bg-black);
    font-size: 20px;
    font-weight: 700;
    border-bottom: 1px solid var(--bg-dark-gray);
    background: var(--bg-card);
}

#modal .conditionBody {
    padding: 20px;
    background: var(--bg-card);
    font-size: 16px;
    color: var(--bg-black);
    /* background: var(--bg-modal); */
}

#modal .conditionBodyRadius {
    border-radius: 0px 0px 10px 10px;
}

#modal .conditionBodyPadding {
    padding: 0px;
}

#modal .theadSize {
    padding: 16px 20px !important;
}

#modal .tableList td {
    padding: 16px 20px !important;
}

#modal .demoComment {
    color: var(--bg-primary);
}

.boxBodyColor {
    color: var(--bg-white);
    text-align: center;
    font-size: 16px;
}

.boxBodyTitle {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    color: var(--bg-white);
    margin-bottom: 30px;
}

.loadPage {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.816);
    transform: translate(0px, 0px) !important;
    color: white;
}

.textLoadding {
    margin-top: 36px;
    font-size: 20px;
    color: #d9d9d9;
}

#modal .boxRegisterAction {
    background: var(--bg-white);
    font-size: 18px;
    padding: 12px 16px;
    color: var(--bg-black);
    width: 320px;
    border-radius: 10px;
    margin-bottom: 12px;
}

#modal .boxContentWidthBigImage {
    width: 440px;
    height: 100%;
}

#modal .imgPopup {
    width: 100%;
    cursor: pointer;
    height: 100%;
    border-radius: 8px;
}

#modal .iconImgPopup {
    position: relative;
    color: white;
    width: 38px !important;
    height: 100% !important;
    cursor: pointer;
    background: var(--bg-navbar);
    border-radius: 50px;
    padding: 6px;
    filter: drop-shadow(0 0 0.2rem var(--bg-black));
}

#modal .closeButtonPopup {
    position: absolute;
    right: 0px;
    padding: 4px;
}

#modal .iconImgPopup:hover {
    top: 2px;
}

.pwaPopupInstallApp {
    background: var(--bg-white);   
    padding: 20px 24px;
    filter: drop-shadow(0 0 0.1rem #1F1F1F);
    border-radius: 0px 0px 6px 6px;
    width: 400px;
}

.pwaPopupInstallApp .pwaIconSize {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.pwaPopupInstallApp .pwaIconTitle {
    font-size: 16px;
}

@media only screen and (max-width: 600px) {
    #modal .boxBodyFull {
        margin: 0px;
    }

    #modal .boxFull {
        min-height: 100vh;
        border-radius: 0px;
        width: 100%;
        border: 0px;
    }

    #modal .boxContent,
    #modal .boxContentError {
        width: 84vw;
        padding: 30px 15px;
    }

    #modal .boxContentFull {
        width: 91vw;
        min-height: 91vh;
        padding: 40px 20px 40px 20px;
        border-radius: 0px;
    }

    #modal .closeImg {
        width: 50px;
        height: 50px;
    }

    #modal .closeImgFull {
        width: 38px;
        height: 38px;
    }

    #modal .iconImgFull {
        width: 36px !important;
    }

    #modal .conditionTitlePaddingOther {
        font-size: 16px;
        padding: 15px 5px 10px 5px;
    }

    #modal .conditionBodyRadius {
        border-radius: 0px;
    }

    #modal .confirmParagrap {
        font-size: 18px;
        margin: 5px 0px 30px 0px;
    }

    #modal .boxTitle {
        font-size: 18px;
    }

    #modal .conditionTitleMobile {
        padding: 20px 5px 10px 5px;
        color: var(--bg-white);
        font-size: 16px;
        border-bottom: 1px solid var(--bg-line);
        background-color: var(--bg-brand);
    }

    #modal .contentPosition {
        /* top: 0;
        left: 0;
        transform: unset; */
    }

    #modal .imgLogo {
        width: 120px;
    }

    .textLoadding {
        margin-top: 24px;
        font-size: 16px;
    }

    #modal .confirmText {
        font-size: 16px;
    }

    #modal .backButtonFull {
        left: -20px;
    }

    #modal .alertMarginTop {
        margin-top: -4px;
    }
    #modal .boxRegisterAction {
        font-size: 18px;
        padding: 8px 16px;
        width: 240px;
        margin-bottom: 10px;
    }

    #modal .boxContentWidthBigImage {
        width: 325px;
        height: 100%;
        max-width: 100%;
    }

    #modal .iconImgPopup {
        width: 32px !important;
        padding: 4px;
    }

    .pwaPopupInstallApp {  
        padding: 16px 20px;
        width: 320px;
    }
    
    .pwaPopupInstallApp .pwaIconTitle {
        font-size: 16px;
    }
}